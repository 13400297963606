import { EmptyState, SendTo } from '@wix/ambassador-wix-form-builder-web/types'
import { isNotEmptyEmailId } from '../../../../utils/utils'
import _ from 'lodash'

export const getEmptyStateFromSendToDefinition = (
  sendTo: SendTo,
  withUpgradeEmails: boolean,
): EmptyState => {
  if (
    (withUpgradeEmails && sendTo?.emails?.emails?.length === 0 && !sendTo?.owner) ||
    (!withUpgradeEmails && sendTo?.emails?.emailIds?.length === 0 && !sendTo?.owner)
  ) {
    return EmptyState.EMAILS
  }
  if (sendTo?.users?.userIds?.length === 0) {
    return EmptyState.USERS
  }
}

export const getRecipients = (emailIds: string[]) => {
  const sendToOwner: boolean = _.isEmpty(emailIds[0])
  const actualEmailIds: string[] = emailIds.filter(isNotEmptyEmailId)

  return { sendToOwner, emailIds: actualEmailIds }
}

export const emptyStateValue = (emptyState?: EmptyState) =>
  emptyState && emptyState !== EmptyState.UNDEFINED ? emptyState : null
